/* styles.css */
.tg  {border-collapse:collapse;border-spacing:0;}
.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  overflow:hidden;padding:10px 5px;word-break:normal; padding: 0.2em;}
.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal; padding: 0.2em;}
.tg .tg-7612{font-family:"Lucida Sans Unicode", "Lucida Grande", sans-serif !important;font-size:14px;text-align:left;
  vertical-align:top}
.tg .tg-4ngr{font-family:"Lucida Sans Unicode", "Lucida Grande", sans-serif !important;font-size:14px;font-weight:bold;
  text-align:left;vertical-align:top}
.tg .tg-cly1{font-weight:bold;text-align:left;vertical-align:middle}